export default {
  "account.banner.about": [
    {
      "type": 0,
      "value": "Tillsammans med klubbarna i Allsvenskan och Superettan erbjuder Svensk Elitfotboll videor och highlights från ligorna och klubbarna på plattformen Fotboll Play. För att se en video behöver du logga in med ett Fotbollskonto. Det är gratis att skapa ett konto."
    }
  ],
  "account.banner.createAccount": [
    {
      "type": 0,
      "value": "Skapa Fotbollskonto"
    }
  ],
  "account.banner.title": [
    {
      "type": 0,
      "value": "Bästa sättet att se videor och highlights från Allsvenskan och Superettan"
    }
  ],
  "account.banner.welcome": [
    {
      "type": 0,
      "value": "Välkommen till"
    }
  ],
  "clip.menu.play": [
    {
      "type": 0,
      "value": "Spela klipp"
    }
  ],
  "clip.menu.share": [
    {
      "type": 0,
      "value": "Dela"
    }
  ],
  "clip.payment.term": [
    {
      "type": 0,
      "value": "För att se innehållet behöver du köpa Pay-Per-View (PPV) av "
    },
    {
      "type": 1,
      "value": "merchant"
    },
    {
      "type": 0,
      "value": " och vara inloggad med ditt Fotbollskonto. PPV innebär att du betalar för ett enskilt innehåll."
    }
  ],
  "clip.payment.term.logged.in": [
    {
      "type": 0,
      "value": "För att se innehållet behöver du köpa Pay-Per-View (PPV) av "
    },
    {
      "type": 1,
      "value": "merchant"
    },
    {
      "type": 0,
      "value": " kopplat till ditt Fotbollskonto. PPV innebär att du betalar för ett enskilt innehåll."
    }
  ],
  "club.welcomeMessage": [
    {
      "type": 0,
      "value": "Välkommen till "
    },
    {
      "type": 1,
      "value": "club"
    },
    {
      "type": 0,
      "value": " Play"
    }
  ],
  "club.welcomeMessage.see.latest": [
    {
      "type": 0,
      "value": "Se det senaste från "
    },
    {
      "type": 1,
      "value": "club"
    }
  ],
  "collectionItem.isPlaying": [
    {
      "type": 0,
      "value": "Spelas nu"
    }
  ],
  "collections.goals": [
    {
      "type": 0,
      "value": "Mål"
    }
  ],
  "collections.goalsByTeam": [
    {
      "type": 0,
      "value": "Mål av "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.goalsInLeague": [
    {
      "type": 0,
      "value": "Mål från "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "collections.greatFreekicks": [
    {
      "type": 0,
      "value": "Bästa frisparkarna"
    }
  ],
  "collections.greatFreekicksByTeam": [
    {
      "type": 0,
      "value": "Bästa frisparkarna av "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.greatFreekicksInLeague": [
    {
      "type": 0,
      "value": "Bästa frisparkarna från "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "collections.greatestGoals": [
    {
      "type": 0,
      "value": "Snyggaste målen - säsongerna 2021-"
    },
    {
      "type": 1,
      "value": "season"
    }
  ],
  "collections.greatestGoalsByTeam": [
    {
      "type": 0,
      "value": "Snyggaste målen av "
    },
    {
      "type": 1,
      "value": "team"
    },
    {
      "type": 0,
      "value": " - säsongerna 2021-"
    },
    {
      "type": 1,
      "value": "season"
    }
  ],
  "collections.greatestGoalsInLeague": [
    {
      "type": 0,
      "value": "Snyggaste målen från "
    },
    {
      "type": 1,
      "value": "league"
    },
    {
      "type": 0,
      "value": " - säsongerna 2021-"
    },
    {
      "type": 1,
      "value": "season"
    }
  ],
  "collections.highlights.assists": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Assists från "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Assists"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Assists av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Assists av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.highlights.conceded.goals": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Insläppta mål från "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Insläppta mål"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Insläppta mål av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Insläppta mål av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.highlights.goals": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Mål från "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Mål"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Mål av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Mål av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.highlights.label.assists": [
    {
      "type": 0,
      "value": "Assists"
    }
  ],
  "collections.highlights.label.conceded.goals": [
    {
      "type": 0,
      "value": "Insläppta mål"
    }
  ],
  "collections.highlights.label.goals": [
    {
      "type": 0,
      "value": "Mål"
    }
  ],
  "collections.highlights.label.penalties": [
    {
      "type": 0,
      "value": "Straffar"
    }
  ],
  "collections.highlights.label.penaltyShots": [
    {
      "type": 0,
      "value": "Straffsparkar"
    }
  ],
  "collections.highlights.label.redCards": [
    {
      "type": 0,
      "value": "Röda kort"
    }
  ],
  "collections.highlights.label.saves": [
    {
      "type": 0,
      "value": "Räddningar"
    }
  ],
  "collections.highlights.label.shots": [
    {
      "type": 0,
      "value": "Avslut"
    }
  ],
  "collections.highlights.label.yellowCards": [
    {
      "type": 0,
      "value": "Gula kort"
    }
  ],
  "collections.highlights.penalties": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Straffar i "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Straffar"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Tilldömda straffar "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Tilldömda straffar "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.highlights.penaltyShots": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Straffsparkar i "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Straffsparkar"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Straffsparkar mot "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Straffsparkar mot "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.highlights.redCards": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Röda kort från "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Röda kort"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Röda kort för "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Röda kort för "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.highlights.saves": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Räddningar från "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Räddningar"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Räddningar av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Räddningar av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.highlights.shots": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Avslut från "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Avslut"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Avslut av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Avslut av "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.highlights.yellowCards": [
    {
      "options": {
        "league": {
          "value": [
            {
              "type": 0,
              "value": "Gula kort från "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Gula kort"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "player": {
          "value": [
            {
              "type": 0,
              "value": "Gula kort för "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        },
        "team": {
          "value": [
            {
              "type": 0,
              "value": "Gula kort för "
            },
            {
              "type": 1,
              "value": "name"
            },
            {
              "type": 1,
              "value": "season"
            }
          ]
        }
      },
      "type": 5,
      "value": "filter"
    }
  ],
  "collections.latestCards": [
    {
      "type": 0,
      "value": "Senaste korten"
    }
  ],
  "collections.latestCardsByTeam": [
    {
      "type": 0,
      "value": "Senaste korten för "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.latestCardsInLeague": [
    {
      "type": 0,
      "value": "Senaste korten från "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "collections.latestGoals": [
    {
      "type": 0,
      "value": "Senaste målen"
    }
  ],
  "collections.latestInterviews": [
    {
      "type": 0,
      "value": "Senaste intervjuerna"
    }
  ],
  "collections.latestPremium": [
    {
      "type": 0,
      "value": "Senaste Premium-innehållet"
    }
  ],
  "collections.latestPremiumByTeam": [
    {
      "type": 0,
      "value": "Senaste Premium-innehållet från "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.latestPremiumInLeague": [
    {
      "type": 0,
      "value": "Senaste Premium-innehållet från "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "collections.latestPressConferences": [
    {
      "type": 0,
      "value": "Senaste presskonferenserna"
    }
  ],
  "collections.latestShots": [
    {
      "type": 0,
      "value": "Senaste avslut"
    }
  ],
  "collections.latestStudioProgram": [
    {
      "type": 0,
      "value": "Senaste studioprogrammen"
    }
  ],
  "collections.latestUploads": [
    {
      "type": 0,
      "value": "Nytt på Fotbollplay"
    }
  ],
  "collections.latestUploadsByTeam": [
    {
      "type": 0,
      "value": "Nytt från "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.penalties": [
    {
      "type": 0,
      "value": "Straffar"
    }
  ],
  "collections.penaltiesByTeam": [
    {
      "type": 0,
      "value": "Straffar av "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.penaltiesInLeague": [
    {
      "type": 0,
      "value": "Straffar i "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "collections.penaltyShots": [
    {
      "type": 0,
      "value": "Straffsparkar"
    }
  ],
  "collections.penaltyShotsByTeam": [
    {
      "type": 0,
      "value": "Straffsparkar av "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.penaltyShotsInLeague": [
    {
      "type": 0,
      "value": "Straffsparkar i "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "collections.popular": [
    {
      "type": 0,
      "value": "Mest populära"
    }
  ],
  "collections.popularByTeam": [
    {
      "type": 0,
      "value": "Mest populära klippen för "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.popularInLeague": [
    {
      "type": 0,
      "value": "Mest populära klippen från "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "collections.redCards": [
    {
      "type": 0,
      "value": "Röda kort"
    }
  ],
  "collections.redCardsByTeam": [
    {
      "type": 0,
      "value": "Röda kort för "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.redCardsInLeague": [
    {
      "type": 0,
      "value": "Röda kort från "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "collections.yellowCards": [
    {
      "type": 0,
      "value": "Gula kort"
    }
  ],
  "collections.yellowCardsByTeam": [
    {
      "type": 0,
      "value": "Gula kort för "
    },
    {
      "type": 1,
      "value": "team"
    }
  ],
  "collections.yellowCardsInLeague": [
    {
      "type": 0,
      "value": "Gula kort från "
    },
    {
      "type": 1,
      "value": "league"
    }
  ],
  "error.statistic.fetch": [
    {
      "type": 0,
      "value": "Kunde inte hämta statistik"
    }
  ],
  "errors.clipBecomesAvailableAt": [
    {
      "type": 0,
      "value": "Klippet blir tillgängligt för alla med ett Fotbollskonto "
    },
    {
      "type": 1,
      "value": "timestamp"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "errors.clipNotYetAvailable": [
    {
      "type": 0,
      "value": "Klippet är inte tillgängligt ännu."
    }
  ],
  "errors.matchNotFound": [
    {
      "type": 0,
      "value": "Matchen med id "
    },
    {
      "type": 1,
      "value": "id"
    },
    {
      "type": 0,
      "value": " existerar inte"
    }
  ],
  "errors.missingLogin": [
    {
      "type": 0,
      "value": "För att se innehållet behöver du logga in med ett Fotbollskonto."
    },
    {
      "type": 1,
      "value": "linebreak"
    },
    {
      "type": 0,
      "value": "Med ett Fotbollskonto får du ta del av innehåll från elitklubbar och ligorna Allsvenskan och Superettan. Utöver klubbmaterial blir klipp från de senaste ligamatchernas tillgängliga dagen efter avslutad omgång."
    },
    {
      "type": 1,
      "value": "linebreak"
    },
    {
      "type": 0,
      "value": "Registreringen är enkel och gratis! "
    }
  ],
  "errors.missingLogin.create": [
    {
      "type": 0,
      "value": "Skapa ett här"
    }
  ],
  "errors.missingLogin.noAccount": [
    {
      "type": 0,
      "value": "Inget Fotbollskonto?"
    }
  ],
  "errors.missingSubscription": [
    {
      "type": 0,
      "value": "Det här klippet kräver ett abonnemang av Highlights Plus."
    }
  ],
  "errors.missingSubscription.alreadySubscriberLogin": [
    {
      "type": 0,
      "value": "Har du redan ett abonnemang?"
    }
  ],
  "errors.missingSubscription.promo": [
    {
      "type": 0,
      "value": "Med abonnemanget Highlights Plus kan du se höjdpunkterna från den här matchen och hela Allsvenskan och Superettan precis när de har hänt. Det kostar 35 kr per månad och abonnemanget är helt utan bindningstid - dessutom går överskottet till din favoritklubb!"
    }
  ],
  "errors.missingSubscription.startSubscription": [
    {
      "type": 0,
      "value": "Starta Highlights Plus"
    }
  ],
  "errors.pageNotFound": [
    {
      "type": 0,
      "value": "Sidan kunde inte hittas"
    }
  ],
  "errors.pageNotFound.goBack": [
    {
      "type": 0,
      "value": "Gå tillbaka"
    }
  ],
  "errors.videoNotFound": [
    {
      "type": 0,
      "value": "Videon med id "
    },
    {
      "type": 1,
      "value": "id"
    },
    {
      "type": 0,
      "value": " existerar inte eller har tagits bort"
    }
  ],
  "filtering.all": [
    {
      "type": 0,
      "value": "Alla"
    }
  ],
  "filtering.both": [
    {
      "type": 0,
      "value": "Båda"
    }
  ],
  "filtering.club": [
    {
      "type": 0,
      "value": "Klubb"
    }
  ],
  "filtering.league": [
    {
      "type": 0,
      "value": "Liga"
    }
  ],
  "filters.pickChannel": [
    {
      "type": 0,
      "value": "Välj kanal"
    }
  ],
  "filters.pickLeague": [
    {
      "type": 0,
      "value": "Välj liga"
    }
  ],
  "filters.pickLeagueOrClub": [
    {
      "type": 0,
      "value": "Välj liga / klubb"
    }
  ],
  "filters.pickPlayer": [
    {
      "type": 0,
      "value": "Välj spelare"
    }
  ],
  "filters.pickPlayerMissingTeam": [
    {
      "type": 0,
      "value": "Välj spelare (välj klubb först)"
    }
  ],
  "filters.pickTeam": [
    {
      "type": 0,
      "value": "Välj klubb"
    }
  ],
  "footer.about": [
    {
      "type": 0,
      "value": "Om Svensk Elitfotboll"
    }
  ],
  "footer.clubsOwnPrivacyPolicy": [
    {
      "type": 0,
      "value": "Klubbarnas egna integritetspolicy"
    }
  ],
  "footer.create.fotbollskonto": [
    {
      "type": 0,
      "value": "skapa eller redigera ditt fotbollskonto"
    }
  ],
  "footer.faq": [
    {
      "type": 0,
      "value": "Vanliga frågor"
    }
  ],
  "footer.highlights": [
    {
      "type": 0,
      "value": "Highlights"
    }
  ],
  "footer.infoBottomLeft": [
    {
      "type": 0,
      "value": "Föreningen Svensk Elitfotboll erbjuder innehåll från samtliga matcher och klubbar i Allsvenskan och Superettan till alla som har ett Fotbollskonto."
    }
  ],
  "footer.infoTopLeft": [
    {
      "type": 0,
      "value": "Fotboll Play - för dig som vill följa svensk fotboll på nära håll. Här hittar du innehåll från Allsvenskan, Superettan och dess klubbar."
    }
  ],
  "footer.integrityPolicy": [
    {
      "type": 0,
      "value": "Integritetspolicy"
    }
  ],
  "footer.matches": [
    {
      "type": 0,
      "value": "Matcher"
    }
  ],
  "footer.special.conditions": [
    {
      "type": 0,
      "value": "Särskilda villkor för Fotboll Play"
    }
  ],
  "footer.termsAndConditions": [
    {
      "type": 0,
      "value": "Allmänna villkor"
    }
  ],
  "game.date.today": [
    {
      "type": 0,
      "value": "idag"
    }
  ],
  "game.header.referee": [
    {
      "type": 0,
      "value": "Domare:"
    }
  ],
  "game.header.round": [
    {
      "type": 0,
      "value": "omgång"
    }
  ],
  "game.highlights": [
    {
      "type": 0,
      "value": "Highlights"
    }
  ],
  "game.info.match.center": [
    {
      "type": 0,
      "value": "Matchcenter"
    }
  ],
  "game.info.statistic": [
    {
      "type": 0,
      "value": "Statistik"
    }
  ],
  "game.live": [
    {
      "type": 0,
      "value": "live"
    }
  ],
  "game.starts.autorefresh": [
    {
      "type": 0,
      "value": "Sidan uppdateras automatiskt"
    }
  ],
  "game.starts.days": [
    {
      "type": 0,
      "value": "Matchen startar "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "imorgon"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "om "
            },
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " dagar"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "game.starts.hours": [
    {
      "type": 0,
      "value": "Matchen startar om "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "en timme"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " timmar"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "game.starts.minutes": [
    {
      "type": 0,
      "value": "Matchen startar om "
    },
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " minuter"
    }
  ],
  "game.starts.soon": [
    {
      "type": 0,
      "value": "Matchen startar inom kort"
    }
  ],
  "game.state.halftime": [
    {
      "type": 0,
      "value": "Paus"
    }
  ],
  "game.timeline.liveUpdating": [
    {
      "type": 0,
      "value": "Uppdateras live"
    }
  ],
  "games.filter.past": [
    {
      "type": 0,
      "value": "tidigare"
    }
  ],
  "games.filter.upcoming": [
    {
      "type": 0,
      "value": "kommande"
    }
  ],
  "header.createAccount": [
    {
      "type": 0,
      "value": "Skapa Fotbollskonto"
    }
  ],
  "header.goToClub": [
    {
      "type": 0,
      "value": "Gå till klubbsida:"
    }
  ],
  "header.login": [
    {
      "type": 0,
      "value": "Logga in"
    }
  ],
  "header.logout": [
    {
      "type": 0,
      "value": "Logga ut"
    }
  ],
  "header.nav.clubsites": [
    {
      "type": 0,
      "value": "Klubbar"
    }
  ],
  "header.nav.highlights": [
    {
      "type": 0,
      "value": "Highlights"
    }
  ],
  "header.nav.league.games": [
    {
      "type": 0,
      "value": "Ligamatcher"
    }
  ],
  "header.nav.matches": [
    {
      "type": 0,
      "value": "Matcher"
    }
  ],
  "header.nav.more": [
    {
      "type": 0,
      "value": "Mer"
    }
  ],
  "header.nav.schedule": [
    {
      "type": 0,
      "value": "Tablå"
    }
  ],
  "header.purchase.premium": [
    {
      "type": 0,
      "value": "Köp Premium"
    }
  ],
  "hero.club.welcomeMessage": [
    {
      "type": 0,
      "value": "Se alla mål "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "och highlights"
        }
      ],
      "type": 8,
      "value": "span"
    }
  ],
  "hero.welcomeMessage": [
    {
      "type": 0,
      "value": "Senaste innehållet från Allsvenskan och Superettan"
    }
  ],
  "highlights.back": [
    {
      "type": 0,
      "value": "Visa alla"
    }
  ],
  "highlights.noResults": [
    {
      "type": 0,
      "value": "Inga resultat"
    }
  ],
  "highlights.paging.showing": [
    {
      "offset": 0,
      "options": {
        "=0": {
          "value": [
            {
              "type": 0,
              "value": "Inga resultat"
            }
          ]
        },
        "one": {
          "value": [
            {
              "type": 0,
              "value": "Visar "
            },
            {
              "type": 1,
              "value": "total"
            },
            {
              "type": 0,
              "value": " av "
            },
            {
              "type": 1,
              "value": "total"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "Visar "
            },
            {
              "type": 1,
              "value": "from"
            },
            {
              "type": 0,
              "value": "-"
            },
            {
              "type": 1,
              "value": "to"
            },
            {
              "type": 0,
              "value": " av "
            },
            {
              "type": 1,
              "value": "total"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "totalPage"
    }
  ],
  "highlights.title": [
    {
      "type": 0,
      "value": "Highlights"
    }
  ],
  "league.matches.title": [
    {
      "type": 0,
      "value": "Ligamatcher"
    }
  ],
  "lineup.starting.eleven": [
    {
      "type": 0,
      "value": "Startelvor"
    }
  ],
  "live.has.ppv": [
    {
      "type": 0,
      "value": "Har du redan köpt Pay-Per-View för "
    },
    {
      "type": 1,
      "value": "title"
    },
    {
      "type": 0,
      "value": "?"
    }
  ],
  "live.payment.price": [
    {
      "type": 0,
      "value": "Du kan se "
    },
    {
      "type": 1,
      "value": "title"
    },
    {
      "type": 0,
      "value": " för "
    },
    {
      "type": 1,
      "value": "price"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "priceCurrency"
    },
    {
      "type": 0,
      "value": " direkt här på Fotbollplay."
    }
  ],
  "live.payment.term": [
    {
      "type": 0,
      "value": "För att se innehållet behöver du köpa Pay-Per-View (PPV) av "
    },
    {
      "type": 1,
      "value": "merchant"
    },
    {
      "type": 0,
      "value": ". PPV innebär att du betalar för ett enskilt innehåll. Kostnaden är "
    },
    {
      "type": 1,
      "value": "price"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "priceCurrency"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "live.purchase.availability": [
    {
      "type": 0,
      "value": "Innehållet blir tillgängligt för köp två timmar innan det går live."
    }
  ],
  "live.purchase.ppv": [
    {
      "type": 0,
      "value": "Köp Pay-Per-View"
    }
  ],
  "live.purchase.read.more": [
    {
      "type": 0,
      "value": "Läs mer i"
    }
  ],
  "live.purchase.withdrawal": [
    {
      "type": 0,
      "value": "villkoren om din ångerrätt"
    }
  ],
  "live.schedule.stream.by": [
    {
      "type": 0,
      "value": "streamas av"
    }
  ],
  "match.center.availability": [
    {
      "type": 0,
      "value": "Matchuppställningen kommer att finnas tillgänglig en timme innan avspark"
    }
  ],
  "matches.title": [
    {
      "type": 0,
      "value": "Matcher"
    }
  ],
  "meta.description": [
    {
      "type": 0,
      "value": "Videor och highlights från klubbarna i Allsvenskan och Superettan"
    }
  ],
  "meta.title": [
    {
      "type": 0,
      "value": "Fotboll Play"
    }
  ],
  "meta.title.club": [
    {
      "type": 0,
      "value": "Fotboll Play | "
    },
    {
      "type": 1,
      "value": "club"
    }
  ],
  "payment.about.subscription": [
    {
      "type": 0,
      "value": "abonnemanget."
    }
  ],
  "payment.has.highlights.plus.question": [
    {
      "type": 0,
      "value": "Har du redan köpt Highlights Plus?"
    }
  ],
  "payment.has.subscription.question": [
    {
      "type": 0,
      "value": "Har du redan köpt ett abonnemang?"
    }
  ],
  "payment.ppv.subscription": [
    {
      "type": 0,
      "value": "För att se innehållet behöver du köpa Pay-Per-View (PPV) eller starta ett abonnemang hos "
    },
    {
      "type": 1,
      "value": "merchant"
    },
    {
      "type": 0,
      "value": ". PPV innebär att du betalar för ett enskilt innehåll. Med ett abonnemang får du tillgång till mer innehåll - har finns information om "
    }
  ],
  "payment.ppv.subscription.price": [
    {
      "type": 0,
      "value": "PPV kostar "
    },
    {
      "type": 1,
      "value": "ppvPrice"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "ppvPriceCurrency"
    },
    {
      "type": 0,
      "value": " och ett abonnemang kostar "
    },
    {
      "type": 1,
      "value": "subscriptionPrice"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "subscriptionPriceCurrency"
    },
    {
      "type": 0,
      "value": " per månad - utan bindningstid!"
    }
  ],
  "payment.ppv.subscription.question": [
    {
      "type": 0,
      "value": "Har du redan köpt ett abonnemang eller Pay-Per-View för "
    },
    {
      "type": 1,
      "value": "title"
    },
    {
      "type": 0,
      "value": "?"
    }
  ],
  "payment.subscription.message": [
    {
      "type": 0,
      "value": "För att se innehållet behöver du starta ett abonnemang hos "
    },
    {
      "type": 1,
      "value": "merchant"
    },
    {
      "type": 0,
      "value": ". Här kan du läsa mer om"
    }
  ],
  "payment.subscription.price": [
    {
      "type": 0,
      "value": "Ett abonnemang kostar "
    },
    {
      "type": 1,
      "value": "price"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "priceCurrency"
    },
    {
      "type": 0,
      "value": " per månad - utan bildningstid!"
    }
  ],
  "playback.nextVideo": [
    {
      "type": 0,
      "value": "Nästa video"
    }
  ],
  "playback.shareClip": [
    {
      "type": 0,
      "value": "Dela"
    }
  ],
  "position.attacker": [
    {
      "type": 0,
      "value": "Anfall"
    }
  ],
  "position.defender": [
    {
      "type": 0,
      "value": "Försvarare"
    }
  ],
  "position.goalkeeper": [
    {
      "type": 0,
      "value": "Målvakt"
    }
  ],
  "position.midfielder": [
    {
      "type": 0,
      "value": "Mittfält"
    }
  ],
  "position.substitution": [
    {
      "type": 0,
      "value": "Avbytare"
    }
  ],
  "premium.purchased": [
    {
      "type": 0,
      "value": "Aktivt abonnemang"
    }
  ],
  "purchase.premium": [
    {
      "type": 0,
      "value": "Starta Abonnemang"
    }
  ],
  "relevant.match.section.showall": [
    {
      "type": 0,
      "value": "Visa alla"
    }
  ],
  "relevant.match.section.title": [
    {
      "type": 0,
      "value": "Aktuella matcher"
    }
  ],
  "share.email": [
    {
      "type": 0,
      "value": "E-post"
    }
  ],
  "share.link": [
    {
      "type": 0,
      "value": "Kopiera länk"
    }
  ],
  "share.link.copied": [
    {
      "type": 0,
      "value": "Kopierat"
    }
  ],
  "statistic.data.missing": [
    {
      "type": 0,
      "value": "Data saknas"
    }
  ],
  "statistic.distances": [
    {
      "type": 0,
      "value": "Antal löpmeter"
    }
  ],
  "statistic.possession": [
    {
      "type": 0,
      "value": "Bollinnehav"
    }
  ],
  "stats.match.played": [
    {
      "type": 0,
      "value": "Spelade matcher"
    }
  ],
  "stream.available.soon": [
    {
      "type": 0,
      "value": "Snart tillgänglig"
    }
  ],
  "stream.date.today": [
    {
      "type": 0,
      "value": "idag"
    }
  ],
  "stream.date.tomorrow": [
    {
      "type": 0,
      "value": "i morgon"
    }
  ],
  "stream.date.yesterday": [
    {
      "type": 0,
      "value": "i går"
    }
  ],
  "stream.waiting.for.stream": [
    {
      "type": 0,
      "value": "Väntar på att stream skall starta"
    }
  ],
  "subscription": [
    {
      "type": 0,
      "value": "Abonnemang"
    }
  ],
  "tags.archive": [
    {
      "type": 0,
      "value": "Arkiv"
    }
  ],
  "tags.booking": [
    {
      "type": 0,
      "value": "Kort"
    }
  ],
  "tags.clubMeeting": [
    {
      "type": 0,
      "value": "Klubbmöte"
    }
  ],
  "tags.corner": [
    {
      "type": 0,
      "value": "Hörna"
    }
  ],
  "tags.education": [
    {
      "type": 0,
      "value": "Utbildning"
    }
  ],
  "tags.endOf1stHalf": [
    {
      "type": 0,
      "value": "Slut på första halvlek"
    }
  ],
  "tags.endOf2ndHalf": [
    {
      "type": 0,
      "value": "Slut på andra halvlek"
    }
  ],
  "tags.endOfGame": [
    {
      "type": 0,
      "value": "Matchen slut"
    }
  ],
  "tags.endOfHalf": [
    {
      "type": 0,
      "value": "Slut på halvlek"
    }
  ],
  "tags.fallback": [
    {
      "type": 0,
      "value": "Händelse"
    }
  ],
  "tags.freeKick": [
    {
      "type": 0,
      "value": "Frispark"
    }
  ],
  "tags.goal": [
    {
      "type": 0,
      "value": "Mål"
    }
  ],
  "tags.highlights": [
    {
      "type": 0,
      "value": "Sammandrag"
    }
  ],
  "tags.interview": [
    {
      "type": 0,
      "value": "Intervju"
    }
  ],
  "tags.match": [
    {
      "type": 0,
      "value": "Match"
    }
  ],
  "tags.misc": [
    {
      "type": 0,
      "value": "Intressant händelse"
    }
  ],
  "tags.offside": [
    {
      "type": 0,
      "value": "Offside"
    }
  ],
  "tags.penalty": [
    {
      "type": 0,
      "value": "Straff"
    }
  ],
  "tags.penaltyGoal": [
    {
      "type": 0,
      "value": "Straffmål"
    }
  ],
  "tags.penaltyMiss": [
    {
      "type": 0,
      "value": "Straffmiss"
    }
  ],
  "tags.practiceMatches": [
    {
      "type": 0,
      "value": "Träningsmatcher"
    }
  ],
  "tags.preseason": [
    {
      "type": 0,
      "value": "Försäsong"
    }
  ],
  "tags.pressConference": [
    {
      "type": 0,
      "value": "Presskonferens"
    }
  ],
  "tags.redCard": [
    {
      "type": 0,
      "value": "Rött kort"
    }
  ],
  "tags.reportage": [
    {
      "type": 0,
      "value": "Reportage"
    }
  ],
  "tags.series": [
    {
      "type": 0,
      "value": "Serie"
    }
  ],
  "tags.shot": [
    {
      "type": 0,
      "value": "Avslut"
    }
  ],
  "tags.shotOnTarget": [
    {
      "type": 0,
      "value": "Avslut på mål"
    }
  ],
  "tags.startOf1stHalf": [
    {
      "type": 0,
      "value": "Avspark första halvlek"
    }
  ],
  "tags.startOf2ndHalf": [
    {
      "type": 0,
      "value": "Avspark andra halvlek"
    }
  ],
  "tags.startOfHalf": [
    {
      "type": 0,
      "value": "Avspark"
    }
  ],
  "tags.studioProgram": [
    {
      "type": 0,
      "value": "Studioprogram"
    }
  ],
  "tags.substitution": [
    {
      "type": 0,
      "value": "Byte"
    }
  ],
  "tags.substitution.in": [
    {
      "type": 0,
      "value": "In"
    }
  ],
  "tags.substitution.off": [
    {
      "type": 0,
      "value": "Utbytt i minut"
    }
  ],
  "tags.substitution.out": [
    {
      "type": 0,
      "value": "Ut"
    }
  ],
  "tags.substitution.play": [
    {
      "type": 0,
      "value": "Inbytt i minut"
    }
  ],
  "tags.sustainability": [
    {
      "type": 0,
      "value": "Hållbarhet"
    }
  ],
  "tags.training": [
    {
      "type": 0,
      "value": "Träning"
    }
  ],
  "tags.yellowCard": [
    {
      "type": 0,
      "value": "Gult kort"
    }
  ],
  "time.period.day": [
    {
      "type": 0,
      "value": "Dag"
    }
  ],
  "time.period.days": [
    {
      "type": 0,
      "value": "Dagar"
    }
  ],
  "time.period.hour": [
    {
      "type": 0,
      "value": "Timme"
    }
  ],
  "time.period.hours": [
    {
      "type": 0,
      "value": "Timmar"
    }
  ],
  "time.period.minute": [
    {
      "type": 0,
      "value": "Minut"
    }
  ],
  "time.period.minutes": [
    {
      "type": 0,
      "value": "Minuter"
    }
  ],
  "time.period.seconds": [
    {
      "type": 0,
      "value": "Sekunder"
    }
  ],
  "timeline.event.playing": [
    {
      "type": 0,
      "value": "Spelas nu"
    }
  ],
  "title.interviews": [
    {
      "type": 0,
      "value": "Intervjuer"
    }
  ],
  "title.pressConferences": [
    {
      "type": 0,
      "value": "Presskonferenser"
    }
  ],
  "user.login.myAccount": [
    {
      "type": 0,
      "value": "Mitt Fotbollskonto"
    }
  ],
  "user.login.subscribePremium": [
    {
      "type": 0,
      "value": "Starta Highlight Plus"
    }
  ]
}